<template>
  <v-row>
    <template v-if="!directoryOpened">
      <v-col cols="4"
        ><v-fade-transition>
          <v-row v-show="showMenu" class="ml-1" align="center" justify="start">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  v-on="on"
                  @click="numericSort"
                  ><v-icon>{{ numericIcon }}</v-icon>
                </v-btn>
              </template>
              <span>nach Produktnummer sortieren</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  v-on="on"
                  @click="alphabeticSort"
                >
                  <v-icon>{{ alphabeticIcon }}</v-icon>
                </v-btn>
              </template>
              <span>nach Produktname sortieren</span>
            </v-tooltip></v-row
          ></v-fade-transition
        >
      </v-col>
      <v-col cols="4">
        <h2 align="center" justify="center">
          <v-icon class="mr-1 mb-1">fas fa-tools</v-icon>
          Zugeordnete Produkte
        </h2>
      </v-col>
      <v-col cols="3">
        <v-fade-transition>
          <v-row v-show="showMenu" align="center" justify="end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="moveMode ? 'primary' : ''"
                  v-bind="attrs"
                  class="mx-1"
                  v-on="on"
                  @click.stop="(moveMode = !moveMode), (editMode = false)"
                >
                  <v-icon>fas fa-folder-open</v-icon>
                </v-btn>
              </template>
              <span>Produkte in Ordner verschieben</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="editMode ? 'primary' : ''"
                  v-bind="attrs"
                  class="mx-1"
                  v-on="on"
                  @click.stop="(editMode = !editMode), (moveMode = false)"
                >
                  <v-icon>fas fa-exchange-alt</v-icon>
                </v-btn>
              </template>
              <span>Reihenfolge bearbeiten</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  v-on="on"
                  @click="directoryDialog = true"
                >
                  <v-icon>fas fa-folder-plus</v-icon>
                </v-btn>
              </template>
              <span>Ordner hinzufügen</span>
            </v-tooltip>
          </v-row>
        </v-fade-transition>
      </v-col>
      <v-col cols="1" class="pr-1">
        <v-row class="ml-1" align="center" justify="start">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :color="showMenu ? 'success' : 'primary'"
                class="mx-1"
                v-on="on"
                @click.stop="handleMenu"
                ><v-icon class="ml-1 mb-1">far fa-edit</v-icon>
              </v-btn>
            </template>
            <span>Bearbeitungsmodus</span>
          </v-tooltip>
        </v-row>
      </v-col>

      <draggable
        v-model="sortedList"
        tag="v-row"
        :animation="200"
        :disabled="!editMode"
        class="mx-2"
      >
        <v-col v-for="(val, i) in sortedList" :key="i" cols="3">
          <ProductCard
            v-if="sortedList[i].type == 'product'"
            :editMode="editMode"
            :itemDrag="itemDrag"
            :moveMode="moveMode"
            :showMenu="showMenu"
            :isFolder="false"
            :sortedList="sortedList[i]"
            :class="editMode || moveMode ? 'shakeIt' : ''"
            @handleSelectedProduct="handleSelectedProduct"
            @handleDrag="handleDrag"
            @itemDragHandler="itemDragHandler"
          />
          <DirectoryCard
            v-else
            :editMode="editMode"
            :moveMode="moveMode"
            :showMenu="showMenu"
            :recentDirectory="recentDirectory"
            :sortedList="sortedList[i]"
            :class="editMode || moveMode ? 'shakeIt' : ''"
            @handleDirectorySelection="handleDirectorySelection"
            @deleteDirectory="deleteDirectory"
            @handleDrop="handleDrop"
          />
        </v-col>
      </draggable>
    </template>
    <template v-else class="pa-12">
      <OpenedDirectory
        :color="sortedList[directoryIndex].color.color"
        :icon="sortedList[directoryIndex].icon.icon"
        :sortedList="sortedList"
        :directoryIndex="directoryIndex"
        @handleSelectedProduct="handleSelectedProduct"
        @removeFromDirectory="removeFromDirectory"
        @updateDirectory="updateDirectory"
      />
    </template>
    <v-dialog v-model="directoryDialog" max-width="300">
      <v-card tile>
        <v-card-title> Neuen Ordner erstellen </v-card-title>
        <v-card-actions align="center" justify="center">
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-text-field
              v-model="dirNameInput"
              label="Ordnername"
              placeholder="Neuer Ordner"
              required
              :rules="nameRules"
            >
            </v-text-field>
            <v-select
              v-model="dirIcon"
              label="Icon"
              :items="allIcons"
              item-text="name"
              item-value="icon"
              return-object
            >
              <template slot="item" slot-scope="data">
                <v-icon class="mr-2"> {{ data.item.icon }} </v-icon>
                <span>{{ data.item.name }}</span>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon class="mr-1" :color="dirIconColor.color">
                  {{ data.item.icon }} </v-icon
                ><span>{{ data.item.name }}</span>
              </template>
            </v-select>
            <v-select
              v-model="dirIconColor"
              label="Farbe"
              :items="allColors"
              item-text="name"
              item-value="color"
              return-object
            >
              <template slot="item" slot-scope="data">
                <v-avatar :color="data.item.color" size="20" class="mr-1" />
                <span>{{ data.item.name }}</span>
              </template>
              <template slot="selection" slot-scope="data">
                <v-avatar
                  :color="data.item.color"
                  size="20"
                  class="mr-2"
                /><span>{{ data.item.name }}</span>
              </template>
            </v-select>
            <v-text-field v-show="false">
              <!--empty v-form can't handle single input forms-->
            </v-text-field>
            <v-btn color="error" class="mx-4" @click="directoryDialog = false">
              Abbrechen
            </v-btn>
            <v-btn
              type="submit"
              :disabled="!valid"
              color="success"
              class="mx-4"
              @click="addDirectory()"
            >
              <v-icon> fas fa-folder-plus </v-icon>
            </v-btn>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ProductCard from "@/components/PDE/ProductCard.vue";
import DirectoryCard from "@/components/PDE/DirectoryCard.vue";
import OpenedDirectory from "@/components/PDE/OpenedDirectory.vue";
import draggable from "vuedraggable";
import api from "@/api/pps";
import { sort } from "@amcharts/amcharts4/.internal/core/utils/Iterator";
import { icons } from "@/data/pdeIcons";
import { colors } from "@/data/pdeIcons";
import { ref, watch, computed, onMounted } from "@vue/composition-api";

export default {
  components: {
    draggable,
    ProductCard,
    DirectoryCard,
    OpenedDirectory,
  },
  props: {
    directoryOpened: {
      type: Boolean,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    selectedUser: {
      type: Array,
      required: true,
    },
    directoryId: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const sortedList = ref([]);
    const allNames = ref([]);
    const droppedElement = ref({});
    const draggedElement = ref({});
    const alphaSort = ref(0);
    const numSort = ref(0);
    const recentDirectory = ref(0);
    const min = ref(1);
    const valid = ref(true);
    const editMode = ref(false);
    const moveMode = ref(false);
    const showMenu = ref(false);
    const itemDrag = ref(false);
    const directoryDialog = ref(false);
    const alphabeticIcon = ref("fas fa-sort-alpha-down");
    const numericIcon = ref("fas fa-sort-numeric-down");
    const dirNameInput = ref("");
    const dirIcon = ref({ name: "Ordner", icon: "fas fa-folder", id: 0 });
    const dirIconColor = ref({ name: "Grau", color: "#7f7f7f", id: 0 });
    const directoryIndex = ref("");
    const allIcons = icons;
    const allColors = colors;
    const nameRules = computed(() => {
      const rules = [];
      if (min.value) {
        const rule = (v) => (v || "").length >= min.value || "Pflichtfeld";
        rules.push(rule);
      }
      if (true) {
        const rule = (v) =>
          !allNames.value.includes(v) || "dieser Name wird bereits verwendet";

        rules.push(rule);
      }
      return rules;
    });

    watch(
      () => sortedList.value,
      (newValue) => {
        var sortedProducts = [];
        allNames.value = [];
        for (var i = 0; i < sortedList.value.length; i++) {
          if (sortedList.value[i].type != "directory") {
            sortedList.value[i].type = "product";
            sortedProducts.push(sortedList.value[i]);
          }
          allNames.value.push(sortedList.value[i].name);
        }
        props.selectedUser[0].userAssignments.products = sortedProducts;
        handleListChange();
      }
    );
    watch(
      () => props.directoryId,
      (newValue) => {
        var id = Object.values(props.directoryId);
        sortedList.value[sortedList.value.length - 1].id = id[0];
      }
    );
    watch(
      () => props.currentStep,
      (newValue) => {}
    );

    onMounted(() => {
      for (
        var i = 0;
        i < props.selectedUser[0].userAssignments.directories.length;
        i++
      ) {
        props.selectedUser[0].userAssignments.directories[i].type = "directory";
        props.selectedUser[0].userAssignments.directories[i].color =
          allColors[
            props.selectedUser[0].userAssignments.directories[i].colorid
          ];
        props.selectedUser[0].userAssignments.directories[i].icon =
          allIcons[props.selectedUser[0].userAssignments.directories[i].iconid];
      }
      sortedList.value = props.selectedUser[0].userAssignments.products
        .concat(props.selectedUser[0].userAssignments.directories)
        .sort(function (a, b) {
          return b.sortIndex - a.sortIndex;
        });
    });

    function handleMenu() {
      showMenu.value = !showMenu.value;
      if (!showMenu.value) {
        editMode.value = false;
        moveMode.value = false;
      }
    }
    function handleSelectedProduct(val) {
      emit("handleSelectedProduct", val);
    }
    function itemDragHandler() {
      itemDrag.value = false;
    }
    function handleDrag(val) {
      itemDrag.value = true;
      draggedElement.value = val;
    }
    function handleMoveToDirectory(directoryId, productId) {
      var sortedIndex = sortedList.value.findIndex((x) => x.id === productId);
      recentDirectory.value = directoryId;
      sortedList.value[
        sortedList.value.findIndex((x) => x.id === directoryId)
      ].products.push(sortedList.value[sortedIndex]);
      sortedList.value.splice(sortedIndex, 1);
      emit(
        "handleMoveToDirectory",
        props.selectedUser[0].id,
        productId,
        directoryId
      );
    }
    function handleDrop(val) {
      droppedElement.value = val;
      handleMoveToDirectory(droppedElement.value.id, draggedElement.value.id);
      itemDrag.value = false;
    }
    function handleDirectorySelection(val) {
      recentDirectory.value = 0;
      handleDirectoryOpened();
      directoryIndex.value = sortedList.value.findIndex((x) => x.id === val);
    }

    function removeFromDirectory(productId, directoryId) {
      var dirIndex = sortedList.value.findIndex((x) => x.id === directoryId);
      var productIndex = sortedList.value[dirIndex].products.findIndex(
        (x) => x.id === productId
      );
      sortedList.value.push(sortedList.value[dirIndex].products[productIndex]);
      sortedList.value[dirIndex].products.splice(productIndex, 1);
      emit(
        "handleRemoveFromDirectory",
        props.selectedUser[0].id,
        productId,
        directoryId
      );
    }
    function addDirectory() {
      sortedList.value.push({
        type: "directory",
        name: dirNameInput.value,
        icon: dirIcon.value,
        color: dirIconColor.value,
        products: [],
        sortIndex: 0,
      });
      emit(
        "addDirectory",
        dirNameInput.value,
        props.selectedUser[0].id,
        dirIcon.value.id,
        dirIconColor.value.id
      );
      dirIcon.value = { name: "Ordner", icon: "fas fa-folder", id: 0 };
      dirIconColor.value = { name: "Grau", color: "#7f7f7f", id: 0 };
      dirNameInput.value = "";
      directoryDialog.value = false;
      handleListChange();
    }
    function updateDirectory(directoryId, directoryName, iconId, colorId) {
      var sortedIndex = sortedList.value.findIndex((x) => x.id === directoryId);
      sortedList.value[sortedIndex].name = directoryName;
      sortedList.value[sortedIndex].icon = allIcons[iconId];
      sortedList.value[sortedIndex].color = allColors[colorId];
      emit("updateDirectory", directoryId, directoryName, iconId, colorId);
    }
    function deleteDirectory(val) {
      var dirIndex = sortedList.value.findIndex((x) => x.id === val);
      sortedList.value = sortedList.value.concat(
        sortedList.value[dirIndex].products
      );
      sortedList.value.splice(
        sortedList.value.findIndex((x) => x.id === val),
        1
      );
      emit("deleteDirectory", val);
    }
    function handleDirectoryOpened() {
      emit("handleDirectoryOpened");
    }
    function handleListChange() {
      props.selectedUser[0].userProductList = sortedList.value;
      for (var i = 0; i < props.selectedUser[0].userProductList.length; i++) {
        props.selectedUser[0].userProductList[i].sortIndex =
          props.selectedUser[0].userProductList.length - i;
      }
      emit("handleListChange", props.selectedUser[0].id, sortedList.value);
    }
    // List Sorts
    function alphabeticSort() {
      alphaSort.value++;
      if (alphaSort.value % 2 == 0) {
        alphabeticIcon.value = "fas fa-sort-alpha-down";
        sortedList.value.sort(function (a, b) {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });
      } else {
        alphabeticIcon.value = "fas fa-sort-alpha-down-alt";
        sortedList.value.sort(function (a, b) {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });
      }
    }
    function numericSort() {
      numSort.value++;
      if (numSort.value % 2 == 0) {
        numericIcon.value = "fas fa-sort-numeric-down";
        sortedList.value.sort(function (a, b) {
          if (a.number > b.number) {
            return -1;
          }
          if (a.number < b.number) {
            return 1;
          }
          return 0;
        });
      } else {
        numericIcon.value = "fas fa-sort-numeric-down-alt";
        sortedList.value.sort(function (a, b) {
          if (a.number < b.number) {
            return -1;
          }
          if (a.number > b.number) {
            return 1;
          }
          return 0;
        });
      }
    }
    return {
      sortedList,
      recentDirectory,
      valid,
      editMode,
      moveMode,
      showMenu,
      itemDrag,
      directoryDialog,
      alphabeticIcon,
      numericIcon,
      dirNameInput,
      dirIcon,
      dirIconColor,
      directoryIndex,
      allIcons,
      allColors,
      nameRules,
      itemDragHandler,
      handleDrag,
      handleMenu,
      handleDrop,
      addDirectory,
      handleDirectorySelection,
      handleMoveToDirectory,
      handleSelectedProduct,
      removeFromDirectory,
      updateDirectory,
      deleteDirectory,
      alphabeticSort,
      numericSort,
    };
  },
};
</script>
<style scoped>
.shakeIt {
  animation: shake 0.9s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: rotate(-0.75deg);
  }
  25% {
    transform: rotate(0.75deg);
  }
  50% {
    transform: rotate(-0.75deg);
  }
  75% {
    transform: rotate(0.75deg);
  }
  100% {
    transform: rotate(-0.75deg);
  }
}
</style>
