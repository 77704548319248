<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="receivedData"
      :server-items-length="totalItemCount"
      :options.sync="pagination"
      :footer-props="footerProps"
      :loading="loading"
    >
      <template v-slot:no-data>
        <v-alert
          :value="true"
          color="primary"
          style="color: #fff"
          :icon="noDataIcon"
          class="ma-2"
        >
          {{
            hasFilters
              ? "Keine Daten verfügbar, bitte überprüfen Sie den Filter"
              : "Keine Daten vorhanden"
          }}
        </v-alert>
      </template>
      <template slot="items" slot-scope="props">
        <td
          v-for="(header, index) in tableHeaders"
          :key="index"
          class="text-center"
        >
          {{ props.item[header.value] }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true
    },
    apiSuffix: {
      type: String,
      required: true
    },
    columnDefinitions: {
      type: Array,
      default() {
        return [];
      }
    },
    search: {
      type: String,
      default() {
        return "";
      }
    },
    filters: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: () => ({
    apiUrl: "",
    selectedPage: 1,
    receivedData: [],
    emptyData: [],
    pagination: {},
    refreshInterval: {},
    footerProps: {
      "items-per-page-options": [5, 10, 25, 50]
    },
    totalItemCount: 0,
    loading: false,
    initialized: false
  }),
  computed: {
    noDataIcon: function() {
      return this.hasFilters ? "warning" : "info";
    },
    hasFilters: function() {
      return this.filters.length > 0;
    },
    tableHeaders: function() {
      return this.columnDefinitions.map(d => {
        var result = {
          text: d.label,
          sortable: false,
          align: "center",
          value: d.prop
        };
        return result;
      });
    }
  },
  watch: {
    search() {
      this.loadData();
    },
    filters() {
      this.pagination.page = 1;
      this.loadData();
    },
    pagination() {
      this.loadData();
    },
    service() {
      this.apiUrl = this.service.apiUrl + this.apiSuffix;
      this.loadData();
    }
  },
  created() {
    this.apiUrl = this.service.apiUrl + this.apiSuffix;

    this.refreshInterval = setInterval(() => {
      this.loadData();
    }, 10000);
  },
  mounted() {
    this.loadData();
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    loadData() {
      const { page, itemsPerPage } = this.pagination;

      var filterParams = {};
      if (itemsPerPage >= 0) {
        (filterParams.limit = itemsPerPage),
          (filterParams.offset = (page - 1) * itemsPerPage);
      }

      const filterSeparator = ";;;";
      const hasFilters = this.filters.length > 0;
      var filterQuery = hasFilters ? this.filters.join(filterSeparator) : "";
      if (this.search && this.search.length > 0) {
        filterQuery += hasFilters ? filterSeparator + this.search : this.search;
      }
      if (filterQuery != "") {
        filterParams.filter = filterQuery;
      }

      this.loading = true;
      this.axios
        .get(this.apiUrl, {
          params: filterParams
        })
        .then(result => {
          this.receivedData = result.data.values;
          this.totalItemCount = result.data.pageInfo.total_count;
          this.$emit("update:page", result.data.pageInfo);
        })
        .catch(e => {
          // console.log(e);
          // if (process.env.IS_ELECTRON) {
          //   log.error(new Error(e));
          // }
        })
        .finally(() => {
          this.initialized = true;
          this.loading = false;
        });
    },
    onSelectedPageChanged() {
      this.loadData();
    }
  }
};
</script>
