<template>
  <p>{{ title }}</p>
</template>

<script>
export default {
  data() {
    return {
      title: "404",
    };
  },
};
</script>
