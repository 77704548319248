<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="filteredDevices"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.callnumber"
            :class="{ selectedRow: item === selectedDevice }"
            class="pointerCursor"
            @click="getDeviceData(item)"
          >
            <td v-if="item.Callnumber != null">
              {{ item.Callnumber }}
            </td>
            <td v-else>
              {{ item.Origin.name }}
            </td>
            <td>
              <v-icon>fa-chevron-right</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:top>
        <v-col cols="12">
          <v-layout wrap>
            <v-row dense justify="space-around">
              <v-col>Übertragungsart</v-col>
              <v-col v-for="type in types" :key="type" cols="12">
                <v-checkbox
                  v-model="selectedTypes"
                  dense
                  :label="type"
                  :value="type"
                />
              </v-col>
            </v-row>
            <v-row dense justify="space-around">
              <v-col>Umgebung</v-col>
              <v-col v-for="env in environments" :key="env" cols="12">
                <v-checkbox
                  v-model="selectedEnvironment"
                  dense
                  :label="env"
                  :value="env"
                />
              </v-col>
            </v-row>
          </v-layout>
          <hr />
        </v-col>
        <v-toolbar flat dense>
          <v-text-field
            ref="textInput"
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            clearable
            single-line
            hide-details
            @click:clear="clearDevice()"
          />
          <v-spacer></v-spacer>

          <v-btn color="primary" :loading="loading" @click="loadAllData">
            Aktualisieren
            <v-icon right>refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import servers from "@/data/servers";
import Promise from "bluebird";
import apiGsmtp from "@/api/gsmtp";
import apiVds from "@/api/vds";

export default {
  setup(props, { emit }) {
    const textInput = ref(null);
    const headers = ref([
      {
        text: "Callnummer",
        value: "Callnumber"
      },
      {
        text: "",
        value: "action",
        sortable: false
      }
    ]);
    const search = ref("");
    const date = ref(new Date().toISOString().substr(0, 10));
    const selectedDevice = ref(null);
    const devices = ref([]);
    const types = ref(["GSMTP", "VDS"]);
    const selectedTypes = ref(["GSMTP", "VDS"]);
    const selectedEnvironment = ref(["Live"]);
    const environments = ref(["Live", "Vision"]);
    const loading = ref(false);
    const filteredDevices = computed(() => {
      return devices.value.filter(
        device =>
          selectedEnvironment.value.indexOf(device.Environment) != -1 &&
          selectedTypes.value.indexOf(device.Type) != -1
      );
    });
    const vdsServices = computed(() => {
      return servers.VDS;
    });
    const gsmtps = computed(() => {
      return servers.GSMTP;
    });

    onMounted(() => {
      loadAllData();
      textInput.value.focus();
    });

    function clearDevice() {
      selectedDevice.value = null;
      emit("input", null);
    }
    function getDeviceData(item) {
      selectedDevice.value = item;
      emit("input", item);
    }
    function groupBy(array, key) {
      return array.reduce(function(rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find(r => r && r.key === v);
        if (el) {
          el.values.push(x);
        } else {
          rv.push({ key: v, values: [x] });
        }
        return rv;
      }, []);
    }

    function loadAllData() {
      clearDevice();
      devices.value.length = 0;
      loading.value = true;

      const promises = [];
      groupBy(vdsServices.value, "environment").map(vdsServiceGroup => {
        vdsServiceGroup.values.forEach(service => {
          promises.push(
            apiVds.getAllDevices(service, {
              type: "vds",
              origin: service,
              environment: vdsServiceGroup.key
            })
          );
        });
      });
      groupBy(gsmtps.value, "environment").map(gsmtpGroup => {
        //TODO: zurzeit werden die vorhandenen Callnummern immer vom ersten GSMTP in der Liste (gruppiert nach environment) ermittelt
        //Zukünftig soll der Business-Server alle Callnummern bereitstellen
        let gsmtp = gsmtpGroup.values[0];
        // gsmtpGroup.values.forEach(gsmtp => {
        promises.push(
          apiGsmtp.getCallnumbers(
            gsmtp.url + ":" + gsmtp.port + "/" + gsmtp.api,
            {
              type: "gsmtp",
              origin: gsmtp,
              environment: gsmtpGroup.key
            }
          )
        );
      });
      Promise.all(promises)
        .then(results => {
          var seen = {};
          results.forEach(result => {
            let data = result.data;
            if (result.config.userData.type == "gsmtp") {
              for (let i = 0; i < data.length; i++) {
                const device = data[i];
                if (!seen.hasOwnProperty(device.Callnumber)) {
                  seen[device.Callnumber] = true;
                  devices.value.push({
                    Type: "GSMTP",
                    Callnumber: device.Callnumber,
                    Environment: result.config.userData.environment,
                    Origin: result.config.userData.origin
                  });
                }
              }
            } else if (result.config.userData.type == "vds") {
              for (let i = 0; i < data.length; i++) {
                const device = data[i];

                if (!seen.hasOwnProperty(device.ID)) {
                  seen[device.ID] = true;
                  devices.value.push({
                    Type: "VDS",
                    Callnumber: device.ID,
                    Environment: result.config.userData.environment,
                    Channels: device.Channels,
                    Origin: result.config.userData.origin
                  });
                }
              }
            }
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }
    return {
      textInput,
      headers,
      search,
      date,
      selectedDevice,
      devices,
      types,
      selectedTypes,
      selectedEnvironment,
      environments,
      loading,
      filteredDevices,
      vdsServices,
      gsmtps,
      clearDevice,
      getDeviceData,
      groupBy,
      loadAllData
    };
  },
  methods: {}
};
</script>

<style></style>
