<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <p class="text-h5 font-weight-bold text-center">GSMTP</p>
        </v-col>
        <v-col>
          <p class="text-h5 font-weight-bold text-center">VDS</p>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-row dense>
            <v-col
              v-for="gsmtp in gsmtpObjs"
              :key="gsmtp.name"
              lg="4"
              md="6"
              cols="12"
            >
              <GSMTPTile
                :gsmtp="gsmtp"
                :class="gsmtp == currentServer ? 'selectedTile' : 'mx-2'"
                @click="showGsmtpDetails(gsmtp)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-divider vertical />
        </v-col>
        <v-col>
          <v-row dense>
            <v-col
              v-for="vds in vdsObjs"
              :key="vds.name"
              lg="4"
              md="6"
              cols="12"
            >
              <VDSTile
                :server="vds"
                :class="vds == currentServer ? 'selectedTile' : 'mx-2'"
                @click="showVDSDetails(vds)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row dense>
            <v-col cols="12"> </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-divider vertical />
        </v-col>
        <v-col>
          <v-row dense>
            <v-col cols="12">
              <v-card class="mx-2">
                <v-card-text>
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>
                          <th class="text-left">Modus</th>
                          <th class="text-left">Colocation</th>
                          <th class="text-left">Version</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="vds in vdsObjs" :key="vds.name">
                          <td>{{ vds.name }}</td>
                          <td>
                            {{ vds.stats.Mode }}
                          </td>
                          <td>
                            {{ vds.legacyProxy.ActiveNode.ip }}
                          </td>
                          <td>
                            {{ vds.stats.Version }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="currentComponent">
          <component
            :is="currentComponent"
            v-if="currentServer != undefined"
            :service="currentServer"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "@vue/composition-api";
import { Component, Vue } from "vue-property-decorator";
import apiGsmtp from "@/api/gsmtp";
import apiVds from "@/api/vds";
import Promise from "bluebird";
import servers from "@/data/servers";
import VDSTile from "@/components/Control/DashboardTab/Tiles/VDSTile.vue";
import GSMTPTile from "@/components/Control/DashboardTab/Tiles/GSMTPTile.vue";
import VDSDetails from "@/components/Control/DashboardTab/Details/VDSDetails.vue";
import GSMTPDetails from "@/components/Control/DashboardTab/Details/GSMTPDetails.vue";

export default {
  components: {
    VDSTile,
    GSMTPTile,
    VDSDetails,
    GSMTPDetails,
  },
  setup(props, { emit }) {
    const currentComponent = ref(null);
    const currentServer = ref(null);
    const gsmtpObjs = ref([]);
    const vdsObjs = ref([]);
    const intervals = ref([]);

    const vdsObjsLive = computed(() => {
      return vdsObjs.value.filter((obj) => obj.environment == "Live");
    });
    const vdsObjsVision = computed(() => {
      return vdsObjs.value.filter((obj) => obj.environment == "Live");
    });
    const gsmtpObjsLive = computed(() => {
      return gsmtpObjs.value.filter((obj) => obj.environment == "Live");
    });
    const gsmtpObjsVision = computed(() => {
      return gsmtpObjs.value.filter((obj) => obj.environment == "Vision");
    });
    onMounted(() => {
      servers.GSMTP.forEach((server) => {
        let gsmtp = {
          loading: false,
          name: server.name,
          available: false,
          environment: server.environment,
          apiUrl: createBaseUrl(server),
          stats: {},
        };
        gsmtpObjs.value.push(gsmtp);
        intervals.value.push(
          startInterval(() => {
            var available = false;
            gsmtp.loading = true;
            apiGsmtp
              .getStats(server.url + ":" + server.port + "/" + server.api)
              .then((result) => {
                available = true;
                gsmtp.stats = result.data;
              })
              .catch(() => {
                available = false;
              })
              .finally(() => {
                gsmtp.available = available;
                gsmtp.loading = false;
              });
          }, 5000)
        );
      });

      servers.VDS.forEach((server) => {
        let vds = {
          loading: false,
          name: server.name,
          available: false,
          environment: server.environment,
          apiUrl: createBaseUrl(server),
          stats: {},
          legacyProxy: {
            ActiveNode: "",
          },
        };
        vdsObjs.value.push(vds);
        intervals.value.push(
          startInterval(() => {
            var available = false;
            vds.loading = true;
            Promise.all([
              apiVds.getStats(server),
              apiVds.getLegacyProxyStats(server),
            ])
              .spread((statsResult, legacyProxyStatsResult) => {
                available = true;
                vds.stats = statsResult.data;
                vds.legacyProxy = legacyProxyStatsResult.data;
              })
              .catch(() => {
                available = false;
              })
              .finally(() => {
                vds.available = available;
                vds.loading = false;
              });
          }, 5000)
        );
      });
    });

    onUnmounted(() => {
      intervals.value.forEach((interval) => {
        clearInterval(interval);
      });
    });

    function createBaseUrl(server) {
      return server.url + ":" + server.port + "/" + server.api;
    }

    function selectServer(server) {
      if (currentServer.value == server) {
        currentServer.value = null;
        return false;
      } else {
        currentServer.value = server;
        return true;
      }
    }

    function showGsmtpDetails(server) {
      selectServer(server);
      currentComponent.value = "GSMTPDetails";
    }

    function showVDSDetails(server) {
      selectServer(server);
      currentComponent.value = "VDSDetails";
    }

    function startInterval(callback, waitTime) {
      callback();
      return setInterval(callback, waitTime);
    }

    return {
      currentComponent,
      currentServer,
      gsmtpObjs,
      vdsObjs,
      intervals,
      vdsObjsLive,
      vdsObjsVision,
      gsmtpObjsLive,
      gsmtpObjsVision,
      createBaseUrl,
      selectServer,
      showGsmtpDetails,
      showVDSDetails,
      startInterval,
    };
  },
};
</script>

<style lang="scss" scoped>
.selectedTile {
  border: 2px #086b7c9f solid;
}
</style>
