<template>
  <v-row>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="4" class="mx-2">
          <v-card-title>Rahmenvertrag speichern</v-card-title>
          <SimFileUpload
            :label="labelContracts"
            :icon="icon"
            @parsed="addContract"
          ></SimFileUpload>
        </v-col>
        <v-col cols="auto">
          <v-divider vertical></v-divider>
        </v-col>
        <v-col cols="7" class="mx-2">
          <v-row class="justify-center">
            <v-card-title class="text-h5 font-weight-bold"
              >Vorschau</v-card-title
            >
          </v-row>
          <v-row>
            <v-data-table
              class="justify-center my-2"
              :headers="tableHeaders"
              :items="uploadedContractDetails"
            >
            </v-data-table></v-row
          ><v-row class="justify-center"
            ><v-btn @click="sendData()" color="success">Speichern</v-btn></v-row
          ></v-col
        >
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { ref } from "@vue/composition-api";
import api from "@/api/sim";
import SimFileUpload from "./SimFileUpload.vue";

export default {
  components: { SimFileUpload },
  setup(props, { emit }) {
    const labelContracts = ref("Rahmenvertrag hinzufügen...");
    const icon = ref("fas fa-file-excel");
    const uploadedContracts = ref(["12345", "67890"]);
    const uploadedContractDetails = ref([]);
    const importantKeys = ref([
      "Rahmenvertrag",
      "Kundennummer",
      "GP/Firmenname",
      "GP/Straße",
      "GP/Wohnort",
      "GP/PLZ",
      "GP/Land",
      "GP/Adresszusatz",
      "Karten-/Profilnummer",
      "Rufnummer",
      "Bindefristende",
      "Kundenkonto",
      "RE/Firmenname",
      "RE/Straße",
      "RE/Wohnort",
      "RE/PLZ",
      "RE/Land",
      "Rechnungszahlart",
      "Kreditinstitut",
      "IBAN",
      "BIC",
      "Rechnungsmedium",
      "Kostenstelle",
      "Kostenstellennutzer",
    ]);
    const contractData = ref({});
    const contractName = ref("");
    const tableHeaders = ref([
      { text: "Rahmenvertrag", value: "Rahmenvertrag" },
      { text: "Kundennummer", value: "Kundennummer" },
      { text: "GPFirmenname", value: "GPFirmenname" },
      { text: "GPStraße", value: "GPStraße" },
      { text: "GPWohnort", value: "GPWohnort" },
      { text: "GPPLZ", value: "GPPLZ" },
      { text: "GPLand", value: "GPLand" },
      { text: "GPAdresszusatz", value: "GPAdresszusatz" },
      { text: "Profilnummer", value: "Profilnummer" },
      { text: "Rufnummer", value: "Rufnummer" },
      { text: "Bindefristende", value: "Bindefristende" },
      { text: "Kundenkonto", value: "Kundenkonto" },
      { text: "REFirmenname", value: "REFirmenname" },
      { text: "REStraße", value: "REFirmenname" },
      { text: "REWohnort", value: "REWohnort" },
      { text: "REPLZ", value: "REPLZ" },
      { text: "RELand", value: "RELand" },
      { text: "Rechnungszahlart", value: "Rechnungszahlart" },
      { text: "Kreditinstitut", value: "Kreditinstitut" },
      { text: "IBAN", value: "IBAN" },
      { text: "BIC", value: "BIC" },
      { text: "Rechnungsmedium", value: "Rechnungsmedium" },
      { text: "Kostenstelle", value: "Kostenstelle" },
      { text: "Kostenstellennutzer", value: "Kostenstellennutzer" },
    ]);

    function addContract(data) {
      for (var i = 0; i < data.length; i++) {
        for (var key in data[i]) {
          if (importantKeys.value.indexOf(key) === -1) {
            delete data[i][key];
          } else {
            if (key === "Karten-/Profilnummer") {
              const replacementKeyTwo = key.replace("Karten-/", "");
              data[i][replacementKeyTwo] = data[i][key];
            } else if (key.includes("/")) {
              const replacementKey = key.replace("/", "");
              data[i][replacementKey] = data[i][key];
              delete data[i][key];
            }
          }
          if (!data[i].hasOwnProperty("IstGekuendigt")) {
            data[i].IstGekuendigt = false;
          }
        }
      }
      contractData.value = data;
      uploadedContractDetails.value = data;
      if (data[0].Rahmenvertrag.startsWith("29394")) {
        contractName.value = "29394 (T1&V1)";
      } else if (data[0].Rahmenvertrag.startsWith("HE351")) {
        contractName.value = "HE351 (T1)";
      } else {
        contractName.value = data[0].Rahmenvertrag;
      }
      uploadedContracts.value = uploadedContracts.value.concat(contractName);
    }
    function sendData() {
      const chunkSize = 1000;
      for (let i = 0; i < contractData.value.length; i += chunkSize) {
        api.callnumberAdd(
          contractName.value,
          contractData.value.slice(i, i + chunkSize)
        );
        emit("contractUploaded", contractData.value);
      }
    }

    return {
      labelContracts,
      icon,
      uploadedContracts,
      addContract,
      uploadedContractDetails,
      tableHeaders,
      sendData,
    };
  },
};
</script>
