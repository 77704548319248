import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./views/Dashboard";
import ServiceManager from "./views/ServiceManager";
import Pps from "./views/PDE";
import PpsManager from "./views/PDEManager";
import Dev from "./views/Dev";
import Excel from "./views/Excel";
import Control from "./views/Control";
import Sim from "./views/Sim";
import Error404 from "./views/errors/Error404";
import AuthService from "./auth/AuthService";

const auth = new AuthService();
const { hasResourceRole } = auth;

Vue.use(Router);

const ROLE_DEV = "devrole";
const ROLE_PPS = "pderole";
const ROLE_PPS_MANAGER = "pdemgrrole";
const ROLE_VDS = "control20role";
const ROLE_EXCEL = "excelrole";
const ROLE_SIM = "simrole";

const router = new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/", redirect: "/dashboard" },
    {
      path: "/dashboard",
      name: "Dashboard",
      components: {
        default: Dashboard
      },
      meta: {
        showInQuickLinks: false
      }
    },
    {
      path: "/control",
      name: "Control 2.0",
      components: {
        default: Control
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.satellite",
        claims: [ROLE_DEV, ROLE_VDS]
      }
    },
    {
      path: "/pps",
      name: "PDE",
      components: {
        default: Pps
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.toolbox",
        claims: [ROLE_DEV, ROLE_PPS]
      }
    },
    {
      path: "/ppsmanager",
      name: "PDE Manager",
      components: {
        default: PpsManager
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.manager",
        claims: [ROLE_DEV, ROLE_PPS_MANAGER]
      }
    },
    {
      path: "/excel",
      name: "Excel",
      components: {
        default: Excel
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.excel",
        claims: [ROLE_DEV, ROLE_EXCEL]
      }
    },
    {
      path: "/servicemanager",
      name: "Service Manager",
      components: {
        default: ServiceManager
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.servicemanager",
        claims: [ROLE_DEV]
      }
    },
    {
      path: "/dev",
      name: "Dev",
      components: {
        default: Dev
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.dev",
        claims: [ROLE_DEV]
      }
    },
    {
      path: "/sim",
      name: "SIM-Kündigung",
      components: {
        default: Sim
      },
      meta: {
        showInQuickLinks: true,
        icon: "$vuetify.icons.sim",
        claims: [ROLE_DEV, ROLE_SIM]
      }
    },
    {
      path: "/404",
      component: Error404
    },
    { path: "*", redirect: "/404" }
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
});

router.getLinksForCurrentUser = function () {
  const result = [];

  //for instead of foreach because you can't break or continue inside a foreach-loop...
  for (let i = 0; i < this.options.routes.length; i++) {
    const link = this.options.routes[i];

    if (link.meta == null || !link.meta.showInQuickLinks) continue;
    if (link.meta.claims) {
      var hasClaim = false;

      for (let i = 0; i < link.meta.claims.length; i++) {
        const claim = link.meta.claims[i];
        if (hasResourceRole(claim)) {
          hasClaim = true;
          break;
        }
      }
      if (hasClaim) {
        result.push(link);
      }
    } else {
      result.push(link);
    }
  }

  return result;
};

router.beforeEach((_, __, next) => {
  if (!router.app.$keycloak.authenticated) {
    window.location.replace(router.app.$keycloak.createLoginUrl());
  }
  next();
});

export default router;
